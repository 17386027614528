import AddChildComponent from "components/children/AddChildComponent";
import ChildCard from "components/children/ChildCard";
import EditChildComponent from "components/children/EditChildComponent";
import TitleWithUnderline from "layouts/titleWithUnderline/TitleWithUnderline";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Children = () => {
  const [showAddChildForm, setShowAddChildForm] = useState(false);
  const [showEditChildForm, setShowEditChildForm] = useState(false);
  const { t } = useTranslation();

  const showAddChildFormHandler = () => {
    setShowAddChildForm(true);
  };

  const showEditChildFormHandler = () => {
    setShowEditChildForm(true);
  };

  return (
    <div>
      <TitleWithUnderline title={t("children list")} className="all-center" />
      <div className="w-100 mt-4 all-center">
        <button
          onClick={() => showAddChildFormHandler()}
          className="fr-primary-button w-100 "
        >
          {t("add new child")}
        </button>
      </div>
      <div className="w-full flex flex-col justify-center">
        <ChildCard showEditChildFormHandler={showEditChildFormHandler}/>
        <ChildCard showEditChildFormHandler={showEditChildFormHandler}/>
        <ChildCard showEditChildFormHandler={showEditChildFormHandler}/>
      </div>
      {/* <button onClick={() => showEditChildFormHandler()}>edit</button> */}
      <AddChildComponent
        showBottomSheet={showAddChildForm}
        setShowBottomSheet={setShowAddChildForm}
      />
      <EditChildComponent
        showBottomSheet={showEditChildForm}
        setShowBottomSheet={setShowEditChildForm}
      />
    </div>
  );
};
export default Children;