import Logo from "assets/images/logo/logo.png";
import classes from "./Header.module.scss";
import { ReactComponent as LeftArrow } from "assets/icons/arrow-left-green.svg";
import { ReactComponent as UserProfile } from "assets/icons/user-profile-circle.svg";
import logoOrange from "assets/images/logo/logo-orange.png";
import useCurrentRoute from "functions/useCurrentRoute ";
import { useNavigate } from "react-router-dom";
import back from "assets/icons/header/chevron-left.png";
import dots from "assets/icons/header/threeDots.png";
import SelectLang from "layouts/selectLang/SelectLang";
const Header = () => {
  const RouteIsHome = useCurrentRoute() === "/";
  const hideHeader = ["/login", "/sign-up", "/landing"];
  const navigate = useNavigate();

  const backHandler = () => {
    // === back one page
    navigate(-1);
  };
  // === if route if out of
  return hideHeader.includes(useCurrentRoute()) ? null : (
    <div
      className={`${classes.header} ${
        RouteIsHome ? "rtl" : "ltr"
      } w-100 h-header flex flex-row items-center justify-between relative`}
    >
      <SelectLang />
      {/* === if route is "/" show profile button and show header in rtl mode else show back and in ltr mode */}
      {RouteIsHome ? (
        <div className={classes.profileContainer}>
          <UserProfile className={classes.profile} />
        </div>
      ) : (
        <div onClick={backHandler} className={classes.backContainer}>
          {/* <LeftArrow className={classes.back} /> */}
          <img src={back} className={classes.back} />
        </div>
      )}
      <div className={classes.logoContainer} onClick={() => navigate("/")}>
        <img src={dots} alt="#" className={classes.logo} />
      </div>
      <div className={classes.logoOrange}>
        <img src={logoOrange} alt="#" />
      </div>
    </div>
  );
};
export default Header;
