// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translations for different languages
import translationEN from "locales/en.json"; // english
import translationAR from "locales/ar.json"; // arabic
import translationFA from "locales/fa.json"; // farsi

// Get the previously selected language from localStorage
// const userLocalSelectedLang = localStorage.getItem("selected-lang") || "en";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR,
    },
    fa: {
      translation: translationFA,
    },
    // Add resources for other languages here
  },

  lng: "en", // Default language
  // lng: userLocalSelectedLang, // Default language
  fallbackLng: "en", // Fallback language
  // debug: true, // Enable debug mode for development
  interpolation: {
    escapeValue: false, // React escapes by default
  },
});

export default i18n;