import React from "react";
import MapImage from "assets/images/temporaryImages/map.jpg";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
const AddressCard = (props: any) => {
  return (
    <div
      onClick={() => {
        props.onClick();
      }}
      className="w-full flex flex-row justify-between p-3 bg-white-backGround rounded-xl mt-3 cursor-pointer"
    >
      <div className="flex flex-col">
        <div className="flex flex-row">
          <HomeIcon className="w-[20px] h-auto" />
          <span className="mx-2 font-semibold">Home</span>
        </div>
        <p className="mt-1">Tehran</p>
        <p className="text-black-textSecondry">Azadi Av, nima allay</p>
      </div>
      <div>
        <img src={MapImage} alt="#" width="70px" height="70px" />
      </div>
    </div>
  );
};

export default AddressCard;
