import { useLocation } from "react-router-dom";
import "./MainWrapper.scss";
const MainWrapper = (props: any) => {
  const location = useLocation();
  const currnetLocation = location.pathname;
  const backgroundFull = ["/login", "/sign-up"];
  return (
    <div
      className={`main-wrapper max-h-screen overflow-hidden relative ${
        backgroundFull.includes(currnetLocation) ? "w-full background-in-landscape" : "w-mainWrapper"
      }`}
    >
      {props.children}
    </div>
  );
};
export default MainWrapper;
