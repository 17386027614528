import { createContext, useContext, useState, ReactNode } from "react";

// Define the type for your context
// type CartContextType = {
//   cart: string[], // Replace string[] with your actual data type
//   setCart: React.Dispatch<React.SetStateAction<string[]>>,
// };

// Create the context with an initial value
const CartContext = createContext(undefined);

// Create a custom hook to use the context
const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartContext must be used within a CartContextProvider");
  }
  return context;
};

// Create a provider component
const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useState([]); // Replace string[] with your initial data type

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContextProvider, useCartContext };
