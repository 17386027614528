// import Axios from "middleware/axiosInstance";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import registerItems from "data/RegisterItems.json";

import closeEye from "assets/images/eye-close-white.svg";
import openEye from "assets/images/eye-open-white.svg";
import LoadingForButton from "layouts/LoadingForButton/LoadingForButton";
import Button from "components/formItems/button/ButtonItem";
import { Link } from "react-router-dom";
import TrayWrapperLoginSignup from "layouts/wrappers/TrayWrapperLoginSignup";
import ButtonItem from "components/formItems/button/ButtonItem";
import LandingAndSignupWrapper from "layouts/landingAndSignupWrapper/LandingAndSignupWrapper";
import leaf from "assets/images/landing/leaf.png";
import fingerSign1 from "assets/images/landing/finger-sign-1.png";
import fingerSign2 from "assets/images/landing/finger-sign-2.png";
import classes from "./SignUp.module.scss";
const SignUp = () => {
  const { t } = useTranslation();

  const [userTypeIsPeron, setUserTypeIsPeron] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [inProccess, setInProccess] = useState(false);

  return (
    <LandingAndSignupWrapper>
      <div className={classes.signup}>
        <div className={classes.appleIconContainer}>
          <img src={leaf} className={classes.leaf} alt="#" />
          <img src={fingerSign1} className={classes.fingerSign1} alt="#" />
          <img src={fingerSign2} className={classes.fingerSign2} alt="#" />
        </div>
        <div className="my-[20px] font-bold text-[20px]">Sign in</div>
        <input
          className={classes.inputNumber}
          type="number"
          placeholder="Phone number"
        />
        <Link to="/" className={`${classes.signupButton} all-center`}>
          Next
        </Link>
      </div>
    </LandingAndSignupWrapper>

    // <TrayWrapperLoginSignup>
    //   <div className="h-full w-full flex items-center justify-center  ">
    //     <div className="w-[280px] md:w-[440px] h-[600px] flex flex-col justify-center items-center">
    //       <form
    //         className="flex flex-col items-center justify-start h-100 mt-4"
    //         autoComplete="off"
    //         // onSubmit={handleLogin}
    //       >
    //         <div className="flex items-center justify-start flex-row w-full">
    //           <label className="switch">
    //             <input
    //               type="checkbox"
    //               defaultChecked={false}
    //               onChange={() => setUserTypeIsPeron(!userTypeIsPeron)}
    //             />
    //             <span className="slider round"></span>
    //           </label>
    //           <p className="mx-1">{t("LegalUser")}</p>
    //         </div>
    //         {registerItems[userTypeIsPeron === true ? "person" : "company"].map(
    //           (item) => {
    //             return item.itemType === "password" ? (
    //               <div className="login-input-box">
    //                 <input
    //                   type={showPassword ? "text" : "password"}
    //                   id={item.id}
    //                   name={item.itemName}
    //                   placeholder={item.placeHolder}
    //                   required
    //                   // onChange={handleLoginInfoChange}
    //                 />
    //                 <label htmlFor="password">{t("password")}</label>
    //                 <img
    //                   src={showPassword ? closeEye : openEye}
    //                   className="show-hide-password-icon"
    //                   onClick={() => setShowPassword(!showPassword)}
    //                   alt="icon"
    //                 />
    //               </div>
    //             ) : (
    //               <div className="login-input-box">
    //                 <input
    //                   type={item.itemType}
    //                   id={item.id}
    //                   name={item.itemName}
    //                   placeholder={item.placeHolder}
    //                   // onChange={handleLoginInfoChange}
    //                   required
    //                 />
    //                 <label htmlFor={item.itemName}>{t([item.itemName])}</label>
    //               </div>
    //             );
    //           }
    //         )}
    //         {/* <div className="login-input-box">
    //         <input
    //           type={showPassword ? "text" : "password"}
    //           id="password"
    //           name="password"
    //           placeholder="password"
    //           required
    //           // onChange={handleLoginInfoChange}
    //           />
    //           <label htmlFor="password">{t("password")}</label>
    //         <img
    //           src={showPassword ? closeEye : openEye}
    //           className="show-hide-password-icon"
    //           onClick={() => setShowPassword(!showPassword)}
    //           alt="icon"
    //         />
    //       </div> */}
    //         <ButtonItem
    //           className="bg-orange-default"
    //           loading={inProccess}
    //           name={t("SignUp")}
    //           onClick={() => console.log("signup")}
    //         />

    //         {/* <button disabled={inProccess} className="login-button bg-red-500">
    //         {inProccess ? <LoadingForButton /> : t("SignUp")}
    //       </button> */}
    //       </form>
    //       <div className="flex flex-row w-full justify-center mt-5 text-[14px]">
    //         <p className="">{t("Already have an Account?")}</p>
    //         <Link className="mx-1 underline" to="/login">
    //           {t("login")}!
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    // </TrayWrapperLoginSignup>
  );
};

export default SignUp;
