import React from "react";
import { ReactComponent as BoyProfileIcon } from "assets/icons/user-profile-boy.svg";
import { useTranslation } from "react-i18next";
interface propsType {
  showEditChildFormHandler: any;
}
const ChildCard: React.FC<propsType> = ({ showEditChildFormHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col px-3 py-2 bg-white-text mt-5 rounded-lg ">
      <div className="flex flex-row items-start">
        <BoyProfileIcon className="w-[20px] h-auto" />
        <div>{t("child name")}</div>
        <div className="ltr:ml-auto rtl:mr-auto ">
          <button
            className="fr-orange-button text-xs bg-orange-300"
            onClick={() => showEditChildFormHandler()}
          >
            {t("edit")}
          </button>
        </div>
      </div>
      <div className="border-t-green-50 w-full h-auto flex flex-row mt-1 font-normal text-xs">
        <div>120 Cm</div>
        <div className="mx-3">32 Kg</div>
      </div>
    </div>
  );
};
export default ChildCard;
