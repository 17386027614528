import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import ButtonItem from "components/formItems/button/ButtonItem";
import InputItem from "components/formItems/input/InputItem";
import { calcFinalFTEE } from "functions/functions";
import TitleWithUnderline from "layouts/titleWithUnderline/TitleWithUnderline";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const AddChildComponent = (props) => {
  const { showBottomSheet, setShowBottomSheet } = props;
  const { t } = useTranslation();

  const [age, setAge] = useState(null);
  const [gender, setGender] = useState("girl");
  const [weight, setWeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [BMI, setBMI] = useState(null);

  const ageChangeHandler = (e) => {
    setAge(e.target.value);
  };
  const heightChangeHandler = (e) => {
    setHeight(e.target.value);
  };
  const WeightChangeHandler = (e) => {
    setWeight(e.target.value);
  };
  // useEffect(() => {
  //   calcFinalFTEE("girl", 17, 40, 90);
  //   console.log("first");
  // }, []);

  const calcBMI = () => {
    // const calcFinalFTEE = (gender, age, weight, height) => {
    if ((age > 0, weight > 0, height > 0)) {
      setBMI(calcFinalFTEE(gender, age, weight, height));
    } else alert("'please complete the form!");
  };

  useEffect(() => {
    setBMI(null);
  }, [showBottomSheet]);
  return (
    <BottomSheetComponent
      showBottomSheet={showBottomSheet}
      setShowBottomSheet={setShowBottomSheet}
    >
      <div className="h-auto my-3  flex flex-col justify-start items-center ">
        <TitleWithUnderline
          title={t("add new child")}
          className="all-center mt-3 mb-2"
        />
        <form className="flex items-start flex-wrap ">
          <InputItem
            inputType="text"
            inputName="childName"
            label={t("child name")}
            placeHolder={t("John Aria")}
            // changeHandler={(e: any) => ageChangeHandler(e)}
          />
          <InputItem
            inputType="number"
            inputName="childAge"
            label={t("child age")}
            placeHolder="15"
            changeHandler={(e) => ageChangeHandler(e)}
          />
          <InputItem
            inputType="number"
            inputName="childHeight"
            label={t("child height")}
            placeHolder="138"
            changeHandler={(e) => heightChangeHandler(e)}
          />
          <InputItem
            inputType="number"
            inputName="childWeight"
            label={t("child weight")}
            placeHolder="60"
            changeHandler={(e) => WeightChangeHandler(e)}
          />
          <select
            className="w-full mt-[10px] bg-[#EDF7E3] h-[36px] border rounded-md"
            name=""
            id=""
            onChange={(e) => setGender(e.target.value)}
          >
            <option value="girl">{t("girl")}</option>
            <option value="boy">{t("boy")}</option>
          </select>
        </form>

        {BMI ? `BMI: ${BMI}` : null}
        <ButtonItem
          className="bg-orange-default mb-4"
          name={t("calc BMI")}
          onClick={() => calcBMI()}
        />
      </div>
    </BottomSheetComponent>
  );
};
export default AddChildComponent;
