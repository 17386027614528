import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/notFound/NotFound";
import Login from "pages/login/Login";
import MainWrapper from "layouts/wrappers/MainWrapper";
import TrayWrapper from "layouts/wrappers/TrayWrapper";
import LandingPage from "pages/landingPage/LandingPage";
import SignUp from "pages/signUp/SignUp";
import Home from "pages/home/Home";
import Cart from "pages/cart/Cart";
import FooterMenu from "layouts/footerMenu/FooterMenu";
import SidebarMenu from "layouts/sidebarMenu/SidebarMenu";
import Condition from "pages/condition/Condition";
import SchoolOrder from "pages/oerder/school/SchoolOrder";
import Header from "layouts/header/Header";
import Addresses from "pages/userAddress/Addresses";
import AddAddress from "components/address/AddAddress";
import AddChild from "pages/addChild/Children";
import OrdersList from "pages/OrdersList/OrdersList";
import Help from "pages/help/Help";
import FAQ from "pages/FAQ/FAQ";
import AboutUs from "pages/aboutUs/AboutUs";
import SelectPaymentMethod from "pages/SelectPaymentMethod";
import ContactUs from "pages/contactUs/ContactUs";
import ProductDetails from "pages/productDetails/ProductDetails";
import OrderDetails from "pages/orderDetails/OrderDetails";
import EventsOrder from "pages/oerder/events/EventsOrder";

const ProjectRoutes = () => {
  return (
    <Router>
      {/* <NavbarAndSidebar /> */}
      <SidebarMenu />
      <MainWrapper>
        <Header />
        <TrayWrapper>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/landing" element={<LandingPage />} />
            <Route path="/order/event" element={<EventsOrder />} />
            <Route path="/order/school" element={<SchoolOrder />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/addresses" element={<Addresses />} />
            <Route path="/add-address" element={<AddAddress />} />
            <Route path="/child" element={<AddChild />} />
            <Route path="/order-list" element={<OrdersList />} />
            <Route path="/condition" element={<Condition />} />
            <Route path="/help" element={<Help />} />
            {/* <Route path="/faq" element={<FAQ />} /> */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/product-details" element={<ProductDetails />} />
            <Route path="/order-details" element={<OrderDetails />} />
            <Route
              path="/select-payment-method"
              element={<SelectPaymentMethod />}
            />
            {/* notfound page */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </TrayWrapper>
        <FooterMenu />
      </MainWrapper>
    </Router>
  );
};

export default ProjectRoutes;
