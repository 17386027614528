import React from "react";
import classes from "./IngredientSlider.module.scss";
import tomato from "assets/images/temporaryImages/tomato.png";

const IngredientSlider = () => {
  return (
    <div className={classes.caloriesContainer}>
      <div className={classes.ingredienSpecification}>
        <span>low salt</span>
        <span>|</span>
        <span>fatty</span>
      </div>
      <div className="flex flex-col items-center ">
        <img src={tomato} alt="#" className="w-[50px]" />
        <span className="text-sm">Tomato</span>
        <span className="text-xs">100g</span>
      </div>
    </div>
  );
};

export default IngredientSlider;
