import React from "react";
import classes from "./SearchBox.module.scss";
import { ReactComponent as Magnifier } from "assets/icons/magnifier.svg";
const SearchBox = (props: any) => {
  return (
    <div className={classes.SearchBoxContainer}>
      <div className={classes.wrapper}>
        <label htmlFor="search" className={classes.labelInput}>
          <Magnifier className={classes.magnifier} />
        </label>
        <input name="search" className={classes.searchInput} />
      </div>
    </div>
  );
};

export default SearchBox;
