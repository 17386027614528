// import Axios from "middleware/axiosInstance";
import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import "./login.scss";
import closeEye from "assets/images/eye-close-white.svg";
import openEye from "assets/images/eye-open-white.svg";
import { useTranslation } from "react-i18next";
import ButtonItem from "components/formItems/button/ButtonItem";
import { Link } from "react-router-dom";
import TrayWrapperLoginSignup from "layouts/wrappers/TrayWrapperLoginSignup";

const Login = () => {
  const { t } = useTranslation();

  // const navigate = useNavigate();
  // const [loginInfo, setLoginInfo] = useState();
  const [showPassword, setShowPassword] = useState(false);
  // const [userPassIsWrong, setUserPassIsWrong] = useState(false);
  const [inProccess, setInProccess] = useState(false);
  // const handleLoginInfoChange = (e) => {
  //   userPassIsWrong && setUserPassIsWrong(false);
  //   const value = e.target.value;
  //   const name = e.target.name;
  //   setLoginInfo({ ...loginInfo, [name]: value });
  // };
  // const handleLogin = (e) => {
  //   setInProccess(true);
  //   e.preventDefault();
  //   Axios.post("/login", loginInfo)
  //     .then(async (res) => {
  //       if (res.status === 200 && res.data.status === "success") {
  //         try {
  //           // get api token and save in localstorage
  //           await localStorage.setItem("token", res.data.authorisation.token);
  //           // account owner name

  //           navigate("/");
  //         } catch {
  //           alert("در ذخیره پارامترهای ورودی مشکلی پیش آمده");
  //         }
  //       } else {
  //         alert("در ذخیره پارامترهای ورودی مشکلی پیش آمده");
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response.status === 403 || err.response.status === 404) {
  //         setUserPassIsWrong(true);
  //       } else {
  //         alert(err.response.data.message);
  //       }
  //     })
  //     .finally(() => setInProccess(false));
  // };
  // const signUp = () => {
  //   alert("لطفا جهت بازیابی رمز عبور با پشتیبانی تماس بگیرید!");
  // };
  return (
    <TrayWrapperLoginSignup>
      <div className="h-full w-full flex items-center justify-center">
        <div className="w-[290px] md:w-[340px] h-[470px] flex flex-col items-center justify-center">
          <form
            className="flex flex-col items-center justify-start h-100 mt-4"
            autoComplete="off"
            // onSubmit={handleLogin}
          >
            {/* <p
            className={`${
              userPassIsWrong ? "wrong-login-message-show" : null
            } wrong-login-message`}
          >
            نام کاربری و رمز عبور وارد شده مطابقت ندارند
          </p> */}
            <div className="login-input-box">
              <input
                type="text"
                id="email"
                name="email"
                placeholder="test@gmail.com"
                // onChange={handleLoginInfoChange}
                required
              />
              <label htmlFor="email">{t("userName")}</label>
            </div>
            <div className="login-input-box">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="password"
                required
                // onChange={handleLoginInfoChange}
              />
              <label htmlFor="password">{t("password")}</label>
              <img
                src={showPassword ? closeEye : openEye}
                className="show-hide-password-icon"
                onClick={() => setShowPassword(!showPassword)}
                alt="icon"
              />
            </div>
            {/* <p className='signup' onClick={signUp}>
                        عضویت/فراموشی رمز
                    </p> */}
            {/* <button disabled={inProccess} className="login-button bg-red-500">
            {inProccess ? <LoadingForButton /> : t("login")}
          </button> */}
            <ButtonItem
              loading={inProccess}
              name={t("login")}
              onClick={() => console.log("login")}
              className="bg-orange-default"
            />
          </form>
          {/* signup message */}
          <div className="flex flex-row w-full justify-center mt-5 text-[14px]">
            <p className="">{t("If you are new?")}</p>
            <Link className="mx-1 underline" to="/sign-up">
              {t("create now")}
            </Link>
          </div>
        </div>
      </div>
    </TrayWrapperLoginSignup>
  );
};

export default Login;
