import { useState } from "react";
import classes from "./SidebarMenu.module.scss";
const SidebarMenu = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    // <div className={`${classes.sidebarContainer}  ${showSidebar ? classes.showSidebar : ""}`}>
    //   <div className="">
    //     <div>
    //       <img src="" alt="" />
    //     </div>
    //     <div>
    //       <p>Sina Ahmadi</p>
    //       <p>test@yumyum.com</p>
    //     </div>
    //   </div>
    //   <div>
    //     <ul>
    //       {[].map((item) => {
    //         return <li>p</li>;
    //       })}
    //       <li></li>
    //     </ul>
    //   </div>
    // </div>
    <></>
  );
};

export default SidebarMenu;
