import React, { useState, useEffect, FunctionComponent } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import * as L from "leaflet";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import pinIcon from "assets/icons/user-profile-circle.svg";
import "leaflet/dist/leaflet.css";
import OpenNotificationWithIcon from "components/notificationWithIcon/OpenNotificationWithIcon ";
const LocationMarker: FunctionComponent = () => {
  // for routing
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [defaultCenter, setDefaultCenter] = useState<any>({
    lat: 34.640552,
    lng: 50.874938,
  });

  const map = useMapEvents({
    click(e: any) {
      if (selectedPoint === null) {
        setSelectedPoint(e.latlng);
        console.log(e.latlng);
        // if origin not set yet,  set origin
        // map.flyTo(defaultCenter, map.getZoom())
        map.flyTo(e.latlng, map.getZoom());
      }
    },
  });

  const userPinIcon = L.icon({
    iconUrl: pinIcon,
    iconSize: [30, 56],
    iconAnchor: [15, 56],
  });

  const removeSelectedPoint = () => {
    setTimeout(() => {
      setSelectedPoint(null);
    }, 50);
  };
  const addlocationHandler =()=>{
    navigate("/addresses");
    // OpenNotificationWithIcon("add address" , "address added successfully")
  }

  return (
    <>
      {selectedPoint === null ? null : (
        <>
          <Marker position={selectedPoint} icon={userPinIcon}>
            <Popup>
              <button
                className="outline-none border-black-100 "
                onClick={() => removeSelectedPoint()}
              >
                {t("delete")}
              </button>
            </Popup>
          </Marker>
        </>
      )}
      <div className="w-full absolute bottom-2 all-center z-[99999999]">
        <button
          className="fr-primary-button bg-orange-900 font-semibold text-"
          onClick={() => addlocationHandler()}
        >
          {t("set location")}
        </button>
      </div>
    </>
  );
};

const AddAddress: FunctionComponent = () => {
  return (
    <div className="w-full h-full">
      <MapContainer center={{ lat: 34.640552, lng: 50.874938 }} zoom={12}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>
    </div>
  );
};

export default AddAddress;