import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import DatePickerComponent from "components/dateAndTimePicker/DatePickerComponent";
import TitleWithUnderline from "layouts/titleWithUnderline/TitleWithUnderline";
import ShowDateDelivery from "components/showDateDelivery/ShowDateDelivery";
import TabForSelect from "components/tabToSelect/TabForSelect";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "components/searchBox/SearchBox";
import ProductList from "components/produstList/ProductList";
// nuts start
import almonds from "assets/images/temporaryImages/nuts/tinyjpg/almonds.jpg";
import cashew from "assets/images/temporaryImages/nuts/tinyjpg/cashew.jpg";
import hazelnut from "assets/images/temporaryImages/nuts/tinyjpg/hazelnut.jpg";
import macadamia from "assets/images/temporaryImages/nuts/tinyjpg/macadamia.jpg";
import PackCard from "components/packCard/PackCard";
import { useTemporaryCartContext } from "context/TemporaryCartContext";
import { useCartContext } from "context/CartContext";
import { checkEventTemporaryCartAndMoveToCart } from "functions/functions";

// end of nuts
const EventsOrder = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deliveryDateAndTime, setDeliveryDateAndTime] = useState(null);
  const [itemTypes, setItemTypes] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const { temporaryCart, setTemporaryCart } = useTemporaryCartContext();
  const { cart, setCart } = useCartContext();
  const [deliveryDate, setDeliveryDate] = useState(null);

  // reset temporary cart on load
  useEffect(() => {
    setTemporaryCart([]);
  }, []);

  useEffect(() => {
    setItemTypes("custom");
    if (deliveryDateAndTime) {
      setDeliveryDate(deliveryDateAndTime?.slice(0, 10));
    }
  }, [deliveryDateAndTime]);

  // useEffect(() => {
  //   if (haveOrderTypeSchoolInSelectedDate(cart, deliveryDate, itemTypes)) {
  //     // console.log("yes we have some order");
  //     // console.log(haveOrderTypeSchoolInSelectedDate(cart, deliveryDate, itemTypes))
  //     const childHaveOrderInThisDate = haveOrderTypeSchoolInSelectedDate(
  //       cart,
  //       deliveryDate,
  //       itemTypes
  //     ).some((item) => item?.child?.id === selectedChild?.id);

  //     if (childHaveOrderInThisDate) {
  //       console.log("childHaveOrderInThisDate");
  //       setSelectedChild(null);
  //       return notification(
  //         "duplicate child",
  //         "child exist this date",
  //         "warning"
  //       );
  //       // }
  //     }
  //   }
  // }, [deliveryDate, selectedChild]);

  const checkTemporaryCartToContinue = () => {
    if (
      checkEventTemporaryCartAndMoveToCart(
        temporaryCart,
        setTemporaryCart,
        deliveryDate,
        cart,
        setCart
      )
    ) {
      navigate("/cart");
    }
  };

  const packData = [
    { nutsName: "almonds", nutsImage: almonds, packPrice: 1 },
    { nutsName: "cashew", nutsImage: cashew, packPrice: 2 },
    { nutsName: "hazelnut", nutsImage: hazelnut, packPrice: 3 },
    { nutsName: "macadamia", nutsImage: macadamia, packPrice: 4 },
  ];

  return (
    <div className="w-full flex flex-col">
      <TitleWithUnderline
        title={t("Select delivery date")}
        className="mt-2 all-center font-semibold"
      />
      <div className="w-full all-center my-4">
        {deliveryDateAndTime === null && (
          <DatePickerComponent
            deliveryDateAndTime={deliveryDateAndTime}
            setDeliveryDateAndTime={setDeliveryDateAndTime}
          />
        )}
      </div>
      {deliveryDateAndTime === null ? null : (
        <ShowDateDelivery
          deliveryDateAndTime={deliveryDateAndTime}
          setDeliveryDateAndTime={setDeliveryDateAndTime}
        />
      )}
      {deliveryDateAndTime === null ? null : (
        <>
          <div className="w-full flex flex-row justify-evenly mt-5">
            <TabForSelect
              itemTypes={itemTypes}
              setItemTypes={setItemTypes}
              firstTab={t("Our choice")}
              secondTab={t("Your choice")}
            />
          </div>
          <div className="w-full h-full mt-5">
            {itemTypes === "pack" ? (
              <>
                {packData.map((pack, index) => {
                  return (
                    <PackCard
                      price={pack.packPrice}
                      nutsImage={pack.nutsImage}
                      nutsName={pack.nutsName}
                      id={index + 100}
                      orderTypeIsSchool={false}
                    />
                  );
                })}
              </>
            ) : (
              <>
                <SearchBox searchText={searchText} />
                <ProductList orderType="pack" />
              </>
            )}
          </div>
          <div className="w-full all-center mt-5">
            <button
              onClick={() => checkTemporaryCartToContinue()}
              className="fr-primary-button block"
            >
              {t("continue")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};
export default EventsOrder;