import React, { useEffect, useState } from "react";
import nut from "assets/images/temporaryImages/nuts/tinyjpg/hazelnut.jpg";
// import almonds from "assets/images/temporaryImages/nuts/tinyjpg/almonds.jpg";
import cashew from "assets/images/temporaryImages/nuts/tinyjpg/cashew.jpg";
import hazelnut from "assets/images/temporaryImages/nuts/tinyjpg/hazelnut.jpg";
import macadamia from "assets/images/temporaryImages/nuts/tinyjpg/macadamia.jpg";

import classes from "./ProductList.module.scss";
import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import ShowNutsDetails from "components/showNutDetails/ShowNutsDetails";

import almonds from "assets/images/temporaryImages/new nuts/almonds.jpg";
import appleJuice from "assets/images/temporaryImages/new nuts/apple juice.jpg";
import apple from "assets/images/temporaryImages/new nuts/apple.jpg";
import banana from "assets/images/temporaryImages/new nuts/banana.jpg";
import cheese from "assets/images/temporaryImages/new nuts/cheese.jpg";
import mangoJuice from "assets/images/temporaryImages/new nuts/mango juice.jpg";
import milk from "assets/images/temporaryImages/new nuts/milk.jpg";
import carrotsAndTomato from "assets/images/temporaryImages/new nuts/carrots and tomato.jpg";
import sheermal from "assets/images/temporaryImages/new nuts/sheermal.jpg";
import strawberry from "assets/images/temporaryImages/new nuts/strawberry.jpg";
import toast from "assets/images/temporaryImages/new nuts/toast.jpg";
import walnut from "assets/images/temporaryImages/new nuts/walnut.jpg";
import cucumber from "assets/images/temporaryImages/new nuts/cucumber and tomato.jpg";
// interface productListProps {
//   // price: number;
//   // nutsImage: any;
//   // nutsName: string;
//   setTemporaryCard?: any;
//   temporaryCard?: any;
//   // id?: number;
// }

const ProductList = ({ orderType }) => {
  const products = [
    {
      category: "nuts",
      categoryId: 0,
      image: almonds,
      product: [
        {
          id: 200,
          name: "almonds",
          image: almonds,
          category: "nuts",
          categoryId: 0,
          sizes: [
            {
              id: 301,
              name: "100g",
              price: 10,
            },
            {
              id: 302,
              name: "200g",
              price: 15,
            },
            {
              id: 303,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
        {
          id: 201,
          name: "walnut",
          image: walnut,
          category: "nuts",
          categoryId: 0,
          sizes: [
            {
              id: 311,
              name: "100g",
              price: 10,
            },
            {
              id: 312,
              name: "200g",
              price: 15,
            },
            {
              id: 313,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      category: "fruits",
      categoryId: 1,
      image: apple,
      product: [
        {
          id: 202,
          name: "apple",
          image: apple,
          category: "fruits",
          categoryId: 1,
          sizes: [
            {
              id: 301,
              name: "100g",
              price: 10,
            },
            {
              id: 302,
              name: "200g",
              price: 15,
            },
            {
              id: 303,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
        {
          id: 203,
          name: "banana",
          image: banana,
          category: "fruits",
          categoryId: 1,
          sizes: [
            {
              id: 311,
              name: "100g",
              price: 10,
            },
            {
              id: 312,
              name: "200g",
              price: 15,
            },
            {
              id: 313,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      category: "vege",
      categoryId: 2,
      image: carrotsAndTomato,
      product: [
        {
          id: 204,
          name: "carrots",
          image: carrotsAndTomato,
          category: "vege",
          categoryId: 2,
          sizes: [
            {
              id: 301,
              name: "100g",
              price: 10,
            },
            {
              id: 302,
              name: "200g",
              price: 15,
            },
            {
              id: 303,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
        {
          id: 205,
          name: "cucumber",
          image: cucumber,
          category: "vege",
          categoryId: 2,
          sizes: [
            {
              id: 311,
              name: "100g",
              price: 10,
            },
            {
              id: 312,
              name: "200g",
              price: 15,
            },
            {
              id: 313,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      category: "bread",
      categoryId: 3,
      image: toast,
      product: [
        {
          id: 206,
          name: "toast",
          image: toast,
          category: "bread",
          categoryId: 3,
          sizes: [
            {
              id: 301,
              name: "100g",
              price: 10,
            },
            {
              id: 302,
              name: "200g",
              price: 15,
            },
            {
              id: 303,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
        {
          id: 207,
          name: "sheermal",
          image: sheermal,
          category: "bread",
          categoryId: 3,
          sizes: [
            {
              id: 311,
              name: "100g",
              price: 10,
            },
            {
              id: 312,
              name: "200g",
              price: 15,
            },
            {
              id: 313,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      category: "Dairy",
      categoryId: 4,
      image: cheese,
      product: [
        {
          id: 208,
          name: "cheese",
          image: cheese,
          category: "Dairy",
          categoryId: 4,
          sizes: [
            {
              id: 301,
              name: "100g",
              price: 10,
            },
            {
              id: 302,
              name: "200g",
              price: 15,
            },
            {
              id: 303,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
        {
          id: 209,
          name: "milk",
          image: milk,
          category: "Dairy",
          categoryId: 4,
          sizes: [
            {
              id: 311,
              name: "100g",
              price: 10,
            },
            {
              id: 312,
              name: "200g",
              price: 15,
            },
            {
              id: 313,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      category: "juice",
      categoryId: 5,
      image: mangoJuice,
      product: [
        {
          id: 210,
          name: "mango juice",
          image: mangoJuice,
          category: "juice",
          categoryId: 5,
          sizes: [
            {
              id: 301,
              name: "100g",
              price: 10,
            },
            {
              id: 302,
              name: "200g",
              price: 15,
            },
            {
              id: 303,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
        {
          id: 211,
          name: "apple juice",
          image: appleJuice,
          category: "juice",
          categoryId: 5,
          sizes: [
            {
              id: 311,
              name: "100g",
              price: 10,
            },
            {
              id: 312,
              name: "200g",
              price: 15,
            },
            {
              id: 313,
              name: "300g",
              price: 20,
            },
          ],
          options: [
            {
              id: 1,
              name: "type1",
              options: [
                {
                  id: 1,
                  name: "salty",
                  value: "salty",
                },
                {
                  id: 2,
                  name: "pepper",
                  value: "pepper",
                },
              ],
            },
            {
              id: 1,
              name: "type2",
              options: [
                {
                  id: 1,
                  name: "with skin",
                  value: "with skin",
                },
                {
                  id: 2,
                  name: "without skin",
                  value: "without skin",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const [showProductDetails, setShowProductDetails] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    if (showProductDetails === false) {
      setSelectedProduct(null);
    }
  }, [showProductDetails]);

  return (
    <div className={classes.container}>
      <div className={classes.categoriesContainer}>
        {products.map((product, index) => {
          return (
            <div
              className={`${classes.category} ${
                index === selectedCategory ? `${classes.activeCategory}` : ""
              }`}
              onClick={() => {
                setSelectedCategory(index);
              }}
            >
              <img
                src={product.image}
                alt="#"
                className={classes.categoryImage}
              />
              <span className={classes.categoryName}>{product.category}</span>
            </div>
          );
        })}
      </div>
      {/* <div className={classes.block}></div> */}
      <div className={classes.productsContainer}>
        {products[selectedCategory].product.map((product) => {
          return (
            <div
              className={classes.product}
              onClick={() => {
                setSelectedProduct(product);
                setShowProductDetails(true);
              }}
            >
              <img
                src={product.image}
                alt="#"
                className={classes.productImage}
              />
              <span className={classes.productName}>{product.name}</span>
            </div>
          );
        })}
      </div>
      <BottomSheetComponent
        showBottomSheet={showProductDetails}
        setShowBottomSheet={setShowProductDetails}
      >
        {selectedProduct && (
          <ShowNutsDetails
            selectedProduct={selectedProduct}
            orderType={orderType}
          />
        )}
      </BottomSheetComponent>
    </div>
  );
};

export default ProductList;
