import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import ButtonItem from "components/formItems/button/ButtonItem";
import InputItem from "components/formItems/input/InputItem";
import TitleWithUnderline from "layouts/titleWithUnderline/TitleWithUnderline";
import { useTranslation } from "react-i18next";

interface propsType {
  showBottomSheet: boolean;
  setShowBottomSheet: any;
}

const EditChildComponent: React.FC<propsType> = ({
  showBottomSheet,
  setShowBottomSheet,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <BottomSheetComponent
        showBottomSheet={showBottomSheet}
        setShowBottomSheet={setShowBottomSheet}
      >
        <div className="h-auto my-3  flex flex-col justify-start items-center ">
          <TitleWithUnderline
            title={t("edit child")}
            className="all-center mt-3 mb-2"
          />
          <form className="flex items-start flex-wrap ">
            <InputItem
              inputType="text"
              inputName="childName"
              label={t("child name")}
              placeHolder={t("John Aria")}
              // changeHandler={(e: any) => ageChangeHandler(e)}
            />
            <InputItem
              inputType="number"
              inputName="childAge"
              label={t("child age")}
              placeHolder="15"
              // changeHandler={(e) => ageChangeHandler(e)}
            />
            <InputItem
              inputType="number"
              inputName="childHeight"
              label={t("child height")}
              placeHolder="138"
              // changeHandler={(e) => heightChangeHandler(e)}
            />
            <InputItem
              inputType="number"
              inputName="childWeight"
              label={t("child weight")}
              placeHolder="60"
              // changeHandler={(e) => WeightChangeHandler(e)}
            />
            <select
              className="w-full mt-[10px] bg-[#EDF7E3] h-[36px] border rounded-md"
              name=""
              id=""
              // onChange={(e) => setGender(e.target.value)}
            >
              <option value="girl">{t("girl")}</option>
              <option value="boy">{t("boy")}</option>
            </select>
          </form>
          <ButtonItem
            className="bg-orange-default"
            name={t("save changes")}
            onClick={() => console.log("first")}
          />
          {/* <button onClick={() => console.log("add child")} name={t("Add")} /> */}
        </div>
      </BottomSheetComponent>
    </div>
  );
};

export default EditChildComponent;
