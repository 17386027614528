import { ReactComponent as Minus } from "assets/icons/minus-xs.svg";
import { ReactComponent as Plus } from "assets/icons/plus-xs.svg";
import BottomSheetComponent from "components/bottomSheet/BottomSheetComponent";
import ShowproductDetails from "components/showProductDetails/ShowproductDetails";
import { useTemporaryCartContext } from "context/TemporaryCartContext";
import { decreaseCountOfPack, increaseCountOfPack } from "functions/functions";
import { useLayoutEffect, useState } from "react";

// interface packCardProps {
//   price: number;
//   nutsImage: any;
//   nutsName: string;
//   settemporaryCart?: any;
//   temporaryCart?: any;
//   id?: number;
// }

const PackCard = ({ id, price, nutsImage, nutsName, orderTypeIsSchool }) => {
  const [count, setCount] = useState(0);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const { temporaryCart, setTemporaryCart } = useTemporaryCartContext();

  useLayoutEffect(() => {
    // if (orderTypeIsSchool) checkCardAndSetCountForSchool();
    checkCardAndSetCountForSchool();
    // else if (!orderTypeIsSchool) {
    //   checkCardAndSetCountForEvent();
    // }
  }, [temporaryCart]);
  // if this card added to cart so we set count of card
  const checkCardAndSetCountForSchool = () => {
    console.log("checkCardAndSetCountForSchool");
    if (id && temporaryCart && temporaryCart.length > 0) {
      const OrderTypeNotSchool =
        temporaryCart.findIndex((item) => item.orderType === "school") === -1;
      const OrderTypeNotPack =
        temporaryCart.findIndex((item) => item.tinyType === "pack") === -1;
      if (OrderTypeNotSchool | OrderTypeNotPack) {
        setCount(0);
      }
      const existingItemIndex = temporaryCart.findIndex(
        (item) => item.id === id
      );
      if (existingItemIndex !== -1) {
        setCount(temporaryCart[existingItemIndex].count);
      } else setCount(0);
    } else if (temporaryCart[0]?.count === 0 || temporaryCart.length === 0)
      setCount(0);
  };

  return (
    <div className="w-full all-center">
      <div className="flex flex-col fr-sm:flex-row fr-sm:items-center items-start  w-full h-full bg-white-text mt-5 rounded-lg ">
        {/* <Link
          to="/product-details"
          className="flex flex-row justify-between w-full"
        > */}
        <div
          onClick={() => {
            setShowProductDetails(true);
          }}
          className="flex flex-row justify-between w-full cursor-pointer"
        >
          <div className="w-[80px] flex justify-center items-center">
            <img
              src={nutsImage}
              alt=""
              width={60}
              height={60}
              className="rounded-lg"
            />
          </div>
          <div className="flex flex-col justify-center items-start mx-3 my-2">
            <span className="text-base">{nutsName}</span>
            <span className="text-sm my-1">500 g</span>
            <div className="text-base flex ">
              <span>{`${price} $`}</span>
              <span className="mx-2 px-2 py-1 bg-green-500 rounded-xl text-xs">
                37% DV
              </span>
            </div>
          </div>
          <div className="m-auto mr-[15%] fr-sm:m-auto">
            <span>{`${price * count} $`}</span>
          </div>
        </div>
        <div className="mt-1 mb-3">
          <div className="all-center h-fit fr-sm:mx-3 mx-[80px] bg-white p-[6px]  rounded-md shadow-[#e2e2e2] shadow-sm">
            <div
              className="p-1 rounded-lg cursor-pointer"
              onClick={() =>
                decreaseCountOfPack(
                  id,
                  price,
                  nutsName,
                  nutsImage,
                  orderTypeIsSchool,
                  temporaryCart,
                  setTemporaryCart,
                  count
                )
              }
            >
              <Minus className="w-[16px] h-auto" />
            </div>
            <div className="w-7 mx-1 text-center"> {count} </div>
            <div
              className="p-1 rounded-lg cursor-pointer"
              onClick={() =>
                increaseCountOfPack(
                  id,
                  price,
                  count,
                  nutsName,
                  nutsImage,
                  orderTypeIsSchool,
                  temporaryCart,
                  setTemporaryCart
                )
              }
            >
              <Plus className="w-[16px] h-auto " />
            </div>
          </div>
        </div>
      </div>

      <BottomSheetComponent
        showBottomSheet={showProductDetails}
        setShowBottomSheet={setShowProductDetails}
      >
        <ShowproductDetails />
      </BottomSheetComponent>
    </div>
  );
};

export default PackCard;
